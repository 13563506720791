<template>
  <div class="commonAgreement_container">
    <div class="agreement_main">
      <div>
        <h1>劳务协议</h1>
        <div>
          甲方：<span>{{ data.agreement_company_name }}</span>
        </div>
        <div style="display: flex">
          乙方：
          <div class="agreement_underline" style="flex: 1 0 0">
            {{ data.name }}
          </div>
        </div>
        <div style="display: flex">
          联系方式：
          <div class="agreement_underline" style="flex: 1 0 0">
            {{ data.phone }}
          </div>
        </div>

        <div class="agreement_margin_p">
          甲方聘用乙方为本项目提供学术活动相关服务。为明确双方义务及权利，依据《中华人民共和国民法典》之合同篇等其他相关法律法规之规定，经双方友好协商，一致同意签订本协议：
        </div>

        <h3>第一条 合同期限</h3>
        <p class="agreement_content_row">
          自本协议签署之日起至本项目结束之日止。
        </p>
      </div>

      <div>
        <h3>第二条：协议内容</h3>
        <p class="agreement_content_row">（一）甲方权利义务</p>
        <p class="agreement_content_row">
          1、甲方有权要求乙方按时按质完成本协议项下约定的学术交流活动。
        </p>
        <p class="agreement_content_row">
          2、甲方应按时向乙方支付相应的课时费报酬。
        </p>
        <p class="agreement_content_row">（二）乙方权利义务</p>
        <p class="agreement_content_row">
          1、乙方应甲方邀请,就甲方举办的学术交流计划按约定分享专业知识和专业经验。切实帮助甲方或者甲方指定的受众提高专业知识认知，促进行业交流与医学技术进步。乙方应恪守专业勤勉的精神，认真按时完成协议约定的交流活动。
        </p>
        <p class="agreement_content_row">
          2、乙方同意并授权甲方将乙方参与会议的内容在会议指定线上教育平台上进行直播和录播。
        </p>
        <p class="agreement_content_row">
          3、甲方双方一致确认本协议项下的劳务费是合理的，并且反映所提供服务的公平市场价值。劳务费的提供完全不构成开出、供应、管理、推荐、购买、使用或出售任何商业产品的义务或诱导，且不以任何方式构成与商业实体或商业产品相关的任何业务机会或乙方就此作出或未来可能作出其他决定的条件。
        </p>
        <p class="agreement_content_row">
          4、甲乙双方陈述并保证，其将遵守一切有关反贿赂及反腐败（《反贿赂法》）的适用法律、法令、法规和准则，包括但不限于美国《反海外腐败法》。
        </p>
        <p class="agreement_content_row">
          5、甲方不得为以下之目的直接或间接向任何政府官员或《反贿赂法》中规定的其他人员、实体或机构，提供或支付有价值的物品：
        </p>
        <p class="agreement_content_row agreement-content-indent">
          1.1.1为任何商业实体获得或维持业务；
        </p>
        <p class="agreement_content_row agreement-content-indent">
          1.1.2不当地影响某一行为或决定而使商业实体获益；和/或
        </p>
        <p class="agreement_content_row agreement-content-indent">
          1.1.3为商业实体谋取不当利益。
        </p>
        <p class="agreement_content_row">
          6、甲乙双方承诺保留准确透明的记录以反映交易与记录。若任何一方有任何理由相信另一方可能违反本条款，应当立即以书面形式告知另一方并与
          另一方协作对该事实情况进行调查和书面记录。
        </p>
        <p class="agreement_content_row">
          7、对本条第3-7款的违反视为对本协议的实质性违反，守约方有权立即终止本协议。
        </p>
        <p class="agreement_content_row">
          8、为传播医学知识或学术交流、或帮助医学教育次发达地区跟进医学发展之目的，甲方或其指定的第三方可能对乙方在本协议下提供服务的过程进行直播和/或录音录像拍照，并对乙方提供服务的过程进行直播、录播、回放和/或对录音录像拍照所取得的影像和声音记录进行编辑、改编、翻译、复制、展示或传播。乙方同意，直播以及录播回放录音录像和拍照内容可包括乙方的肖像和/或全名、教育背景、所服务的机构、职业经历等，并同意甲方或其指定的第三方有权以上述目的使用前述内容。在法律允许的范围内，甲方拥有与录音录像拍照所取得的影像和声音记录相关的所有版权和其他知识产权。
        </p>
        <p class="agreement_content_row">
          9、本活动由第三方提供资金支持，为了遵循资金支持方的合规管理要求，甲方需要在必要范围内向资金支持方提供乙方在本协议项下提供服务的支持材料（例如：讲课照片、音视频、服务条款与条件、付款凭证等），当中可能包含乙方的敏感个人信息。资金支持方应当依法保障乙方的个人信息权益，乙方可通过https://www.beigene.com/privacy-policy/cn/查阅资金支持方的个人信息处理规则。
        </p>
      </div>

      <div>
        <h3>第三条：乙方提供的学术活动相关服务内容:</h3>
        <div class="agreement_margin_p">
          于
          <span class="agreement_underline">{{
            data.meet_start_time?.split("-")[0]
          }}</span
          >年
          <span class="agreement_underline">{{
            data.meet_start_time?.split("-")[1]
          }}</span
          >月
          <span class="agreement_underline">{{
            data.meet_start_time?.split("-")[2].substr(0, 2)
          }}</span
          >日<span class="agreement_underline"> {{ data.project_name }} </span
          >（本协议中简称为“本项目”）中，邀请乙方作为<span
            class="agreement_underline"
            >{{ data.role_name }}</span
          >，将根据甲方的课程进行<span class="agreement_underline">{{
            content[data.role_name]
          }}</span
          >。
        </div>
      </div>

      <div>
        <h3>支付信息如下：</h3>
        <p class="agreement_content_row">
          1、协议费用总额（税后）<span class="agreement_underline">
            ¥ {{ data.money }}（大写{{
              $tools.intToChinese(data.money)
            }}
            ）</span
          >
        </p>
        <p class="agreement_content_row">
          2、乙方提供相关学术交流活动后，经甲乙双方签字确认，甲方在30日个工作日内以银行转账的形式支付。
        </p>
        <div style="display: flex">
          工作单位：
          <div class="agreement_underline" style="flex: 1 0 0">
            {{ data.hospital }}
          </div>
        </div>
        <div style="display: flex">
          身份证号（外宾填写护照号）：
          <div class="agreement_underline" style="flex: 1 0 0">
            {{ data.id_card_number }}
          </div>
        </div>
        <div style="display: flex">
          开户银行地址：
          <div style="flex: 1 0 0" class="agreement_underline">
            {{ data.bank_province }}{{ data.bank_province ? "省" : ""
            }}{{ data.bank_city }}
          </div>
        </div>
        <div style="display: flex">
          开户银行全称（具体到支行）：
          <div class="agreement_underline" style="flex: 1 0 0">
            {{ data.bank_name }}{{ data.bank_name_point }}
          </div>
        </div>
        <div style="display: flex">
          账号：
          <div class="agreement_underline" style="flex: 1 0 0">
            {{ data.bank_number }}
          </div>
        </div>
      </div>
    </div>
    <div class="agreement_sign_info">
      <div class="agreemtn_sign_them">
        <div class="agreement_sign_name">
          <span>甲方（盖章）：</span>{{ data.agreement_company_name }}
        </div>
        <div class="agreement_sign_date">
          日期：{{ $tools.getDate(data.sign_time, "yyyy年MM月dd日") }}
        </div>
      </div>
      <div class="agreemtn_sign_owner">
        <div class="agreement_sign_name">
          <span ref="signData_one">乙方：</span>
          <customButton
            @click="doSignName(0)"
            v-if="!(data.meet_sign_name?.split(',')[0] || this.signData[0])"
          >
            <div>
              <span>签名</span>
            </div>
          </customButton>
          <img
            :src="data.meet_sign_name?.split(',')[0] || this.signData[0]"
            @click="doSignName(0)"
            v-else
            alt=""
          />
        </div>
        <div class="agreement_sign_date">
          日期：{{ $tools.getDate(data.sign_time, "yyyy年MM月dd日") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import customButton from "@/components/unit/CustomButton";
import { CircleCheck } from "@element-plus/icons";
import "../../../../assets/icon/iconfont.css";
export default {
  name: "commonAgreement",
  props: {
    agreementData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    agreementFields: {
      type: Object,
      default: () => {
        return [];
      },
    },
    signData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      data: {},
      content: {
        大会主席: "大会开场发表致辞以及总结",
        会议讲者: "就学术专题发表演讲",
        讨论嘉宾: "参与会议讨论环节的讨论、点评",
      },
      role: ["大会主席", "会议讲者", "讨论嘉宾"],
    };
  },
  components: {
    customButton,
    CircleCheck,
  },
  mounted() {
    this.initData(this.agreementData, this.agreementFields);
    setTimeout(() => {
      this.$emit("initScroll");
    }, 1000);
  },
  methods: {
    initData(data) {
      this.data = data;
      this.role.map((v) => {
        if (v.includes(this.data.role_name)) this.data.role_name = v;
      });
    },
    verifyData() {
      if (!this.signData[0]) {
        let height = this.$refs.signData_one.offsetTop;
        this.$emit("scrollTo", height - 100);
        this.$tips.error({ text: "请签署签名" });
        return false;
      }
      // if (!this.signData[1]) {
      //   let height = this.$refs.signData_twe.offsetTop;
      //   this.$emit("scrollTo", height - 100);
      //   this.$tips.error({ text: "请先签署第二个签名" });
      //   return false;
      // }
      return true;
    },
    doSignName(index) {
      this.$emit("doSignName", index);
    },
  },
};
</script>
<style>
.commonAgreement_container {
  word-break: break-all;
}
.agreement_sign_info {
  display: flex;
}
.agreemtn_sign_them {
  margin-right: 0.25rem;
}
.agreement_sign_info > div {
  flex: 1 0 0;
  margin-top: 1.5rem;
}
.agreement_sign_name {
  height: 2.5rem;
  display: flex;
  align-items: center;
}
.agreement_sign_name span {
  white-space: nowrap;
}
.agreement_sign_name img {
  width: 6rem;
  height: auto;
}
.agreement_margin_p {
  margin: 0.75rem 0;
}
.agreement_font_bold {
  font-weight: 600;
}
.agreement_content_two_stage_title {
  text-indent: 1rem;
}
.attached_table {
  margin-top: 1.25rem;
}
.commonAgreement_container .agreement_table {
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  margin-bottom: 2rem;
}
.commonAgreement_container .agreement_row {
  display: flex;
  min-height: 1.5rem;
}
.agreement_td {
  /* font-size: 4vw; */
  padding: 0.25rem 0.5rem;
  line-height: 1.5rem;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  word-break: break-all;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.commonAgreement_container .agreement_td div {
  line-height: 1.2rem;
}
.commonAgreement_container
  .agreement_table_one
  .agreement_row
  .agreement_td:first-child {
  flex: 1 0 0;
}
.commonAgreement_container
  .agreement_table_one
  .agreement_row
  .agreement_td:last-child {
  flex: 8.75rem 0 0;
  border-right: 0;
}
.commonAgreement_container
  .agreement_table_two
  .agreement_row
  .agreement_td:first-child {
  flex: 5.25rem 0 0;
}
.commonAgreement_container
  .agreement_table_two
  .agreement_row
  .agreement_td:last-child {
  flex: 1 0 0;
  border-right: 0;
}
.commonAgreement_container .agreement_row:last-child > .agreement_td {
  border-bottom: 0;
}
.commonAgreement_split_line {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  margin-top: 1.25rem;
}
.split_line {
  height: 0px;
  flex: 1 0 0;

  border-bottom: #000 0.25rem dashed;
}
.agreement_td_ul {
  padding-left: 1rem;
}
.agreement_td_direction {
  flex-direction: inherit !important;
  /* align-items: center; */
}
.icon-check {
  /* font-size: 1.5rem !important; */
}
</style>