<template>
  <div class="autograph_container" id="autograph">
    <div class="agreement_sign_name_block">
      <div class="agreement_sign_name_bg" @click="closeSignName"></div>
      <div class="agreement_autograph">
        <div class="agreement_autograph_back">
          <div class="agreement_autograph_text">签名</div>
          <div class="agreement_autograph_row agreement_autograph_row_line">
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
          </div>
          <div class="agreement_autograph_row agreement_autograph_row_line">
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
          </div>
          <div class="agreement_autograph_row">
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
          </div>
          <div class="agreement_autograph_content">
            <canvas ref="canvas"></canvas>
            <div
              class="agreement_autograph_content_mask"
              v-if="$tools.isWindows()"
              @mousedown="touchstart"
              @mousemove="touchmove"
              @mouseup="touchend"
              @mouseleave="touchcancel"
            ></div>
            <div
              class="agreement_autograph_content_mask"
              v-else
              @touchstart="touchstart"
              @touchmove="touchmove"
              @touchend="touchend"
              @touchcancel="touchcancel"
            ></div>
          </div>
        </div>
        <div class="agreement_btn_sign_group">
          <div>
            <el-button type="info" size="small" @click.stop="clearSignName"
              >清空</el-button
            >
            <el-button type="primary" size="small" @click.stop="takeSignName"
              >确认</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="agreement_loading_block" v-if="isUpload">
      <div class="agreement_loading_bg"></div>
      <div class="agreement_loading_content">
        <i class="el-icon-loading"></i>
        <p>协议正在保存中，请不要关闭页面</p>
      </div>
    </div>
  </div>
</template>
<script>
import { ElIcon, ElButton } from "element-plus";
import uploads from "@/common/uploads";
export default {
  name: "autograph",
  props: {
    meetingData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isUpload: false,
      canvas: null,
      context: null,
      isCanWrite: false,
      drawCanvas: {
        x: 0,
        y: 0,
      },
      uploadItem: {
        upload_type: "agreement",
      },
      isMove: false,
    };
  },
  components: {
    ElIcon,
    ElButton,
  },
  mounted() {
    this.initCanvas();
  },

  beforeUnmount() {
    document
      .getElementById("autograph")
      .removeEventListener("touchmove", this.$tools.noScroll, {
        passive: false,
      });
  },
  created() {
    this.$nextTick(() => {
      document
        .getElementById("autograph")
        .addEventListener("touchmove", this.$tools.noScroll, {
          passive: false,
        });
    });
  },
  methods: {
    closeSignName() {
      this.$emit("closeSign");
    },
    initCanvas() {
      this.$nextTick(() => {
        this.canvas = this.$refs.canvas;
        this.context = this.canvas.getContext("2d");
      });
    },
    touchstart(event) {
      console.log(111);
      this.isMove = true;
      this.isCanWrite = true;
      let dom = event?.touches?.[0] || event;
      let position = this.canvas.getBoundingClientRect();
      this.drawCanvas = {
        x: dom.clientX - this.canvas.offsetLeft - position.left,
        y: dom.clientY - this.canvas.offsetTop - position.top,
      };
      // this.scroll.disable();
    },
    touchmove(event) {
      if (
        this.start_time > Date.now() - 10 ||
        (this.$tools.isWindows() && !this.isCanWrite)
      )
        return;
      this.start_time = Date.now() + 10;
      let dom = event?.touches?.[0] || event;
      console.log(dom);
      let position = this.canvas.getBoundingClientRect();
      let target = {
        x: dom.clientX - this.canvas.offsetLeft - position.left,
        y: dom.clientY - this.canvas.offsetTop - position.top,
      };
      this.$nextTick(() => {
        this.drawSignName(target);
        this.drawCanvas = { ...target };
      });
    },
    touchend(event) {
      this.isCanWrite = false;
      this.initPoint();
      // this.scroll.enable();
    },
    touchcancel(event) {
      this.isCanWrite = false;
      this.initPoint();
      // this.scroll.enable();
    },
    initPoint() {
      // this.drawCanvas = {
      //   x:0,
      //   y:0
      // }
    },
    drawSignName(target) {
      this.context.beginPath();
      this.context.globalAlpha = 1;
      this.context.lineWidth = 2;
      this.context.strokeStyle = "#000";
      let xRation = this.canvas.width / this.canvas.clientWidth;
      let yRation = this.canvas.height / this.canvas.clientHeight;
      this.context.moveTo(
        this.drawCanvas.x * xRation,
        this.drawCanvas.y * yRation
      );
      this.context.lineTo(target.x * xRation, target.y * yRation);
      this.context.closePath();
      this.context.stroke();
    },
    clearSignName() {
      this.isMove = false;
      this.context.clearRect(
        0,
        0,
        this.canvas.width + this.canvas.offsetLeft,
        this.canvas.width + this.canvas.offsetTop
      );
    },
    createSignature() {
      this.showLink = !this.showLink;
    },
    async takeSignName() {
      if (!this.isMove) return this.$tips.error({ text: "请签名" });
      console.log("this.meetingData :>> ", this.meetingData);
      this.isUpload = true;
      let file = this.canvas.toDataURL("image/png");
      let signNameURL = await uploads.uploadBase64(
        file,
        "",
        this.uploadItem
        // {
        //   "login-company-id": this.meetingData.company_id,
        // }
      );
      this.isUpload = false;
      this.clearSignName();
      this.$emit("getSignNameURL", signNameURL);
    },
  },
};
</script>
<style>
.autograph_container {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.agreement_sign_name_block {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreement_sign_name_bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #f1f1f1;
  opacity: 0.5;
}
.agreement_sign_name_bg_content {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
}
.agreement_autograph {
  width: 100%;
  padding: 0 0.25rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
.agreement_autograph_back {
  width: 96%;
  height: 15.5rem;
  border: 1px solid #111;
  position: relative;
}
.agreement_autograph_row {
  display: flex;
  box-sizing: border-box;
}
.agreement_autograph_row_line {
  border-bottom: 1px dashed #e9e9e9;
}
.agreement_autograph_col {
  height: 5rem;
  width: 25%;
  background: #fff;
  border-right: 1px dashed #e9e9e9;
}
.agreement_autograph_row > .agreement_autograph_col:last-child {
  border: none;
}
.agreement_autograph_text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7.5rem;
  color: #e6e6e6;
  opacity: 0.5;
}
.agreement_autograph_content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreement_autograph_content img {
  width: 80%;
  height: auto;
}
.agreement_autograph_content canvas {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.agreement_btn_sign_group {
  flex: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 999;
}
.agreement_btn_sign_group button {
  width: 6rem;
}
.agreement_loading_block,
.agreement_loading_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.agreement_loading_bg {
  z-index: 100;
  background: gray;
  opacity: 0.7;
}
.agreement_loading_block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreement_loading_content {
  position: relative;
  z-index: 101;
  color: #fff;
  text-align: center;
  font-size: 2rem;
}
.agreement_loading_content p {
  font-size: 1.25rem;
  /* color:#fff; */
}
.autograph_container .el-button--primary {
  background: #0000c9;
  border-color: #0000c9;
}
.agreement_autograph_content_mask {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  background: rgb(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}
</style>