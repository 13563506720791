<template>
  <div class="agreement_container">
    <!-- <itemScroll ref="itemScroll"> -->
    <div class="agreement_main">
      <component
        v-if="components"
        :agreementData="data"
        :signed="$route.query.open_type == 'signed'"
        :signData="config.signNameUrlList"
        @initScroll="initScroll"
        @scrollTo="scrollTo"
        @doSignName="doSignName"
        ref="agreementDOM"
        v-bind:is="components"
      ></component>
      <div
        class="agreemtn_button_submit global_container_center"
        v-if="!data.sign_url"
      >
        <!-- <el-button
            class="sign_block_button_item"
            v-if="$route.query.open_type !== 'signed'"
            size="small"
            type="primary"
            @click.stop="createSignature"
            icon="el-icon-share"
            round
            >转发电子协议签署链接
          </el-button> -->
        <el-button
          class="sign_block_button_item"
          size="small"
          type="primary"
          @click="doCommit()"
          >确认提交</el-button
        >
      </div>
    </div>
    <!-- </itemScroll> -->
    <!-- <div class="showShare_block" v-show="showShare" @click="showShare = false">
      <div class="showShare_bg"></div>
      <div class="showShare_main">
        <img
          class="showShare_img"
          :src="require('@/assets/images/share.png')"
          alt=""
        />
      </div>
    </div> -->
    <autoGraph
      v-if="showSignName"
      @closeSign="closeSign()"
      :meetingData="data"
      @getSignNameURL="getSignNameURL"
    ></autoGraph>
  </div>
  <Popup v-if="showPopup" :msg="msg" @doClose="doClose">
    <div class="agreement-popup-main">
      <div
        class="agreement-popup-content"
        style="text-align: center; font-weight: bold; font-size: 0.9rem"
      >
        <div class="agreement-popup-tag">骨转百讯项目组</div>
        <div class="agreement-popup-tag">诚邀您对本次活动进行评价</div>
      </div>
      <div class="agreement-popup-content">
        <span class="agreement-popup-tag">您对本次活动的整体评价</span>
        <el-rate
          v-model="scoreDate.level"
          @click="handleRatingChange"
          :colors="['#e64638', '#e64638', '#e64638']"
        />
      </div>
      <div class="agreement-popup-content">
        <div class="agreement-popup-tag">
          请输入您对此次活动的反馈（非必填）
        </div>
        <el-input
          v-model="scoreDate.content"
          :rows="2"
          type="textarea"
          placeholder="请输入......"
        />
      </div>
      <div
        class="agreement-popup-content"
        v-if="data.is_selection == 0"
        style="display: flex; align-items: center"
      >
        <!-- <div class="agreement-popup-tag"> -->
        <el-checkbox v-model="status" size="small" />
        <span>
          同意将本场活动参与评选
          <span class="colorRed" @click="checkSelection">【活动评选公告】</span>
        </span>
        <!-- </div> -->
      </div>

      <div class="agreement_popup_btn_group">
        <div @click="doSure" class="agreement_popup_btn_sure">
          <CustomButton size="small"> 提交</CustomButton>
        </div>
        <div @click="doClose" class="agreement_popup_btn_close">退出</div>
      </div>
    </div>
  </Popup>
</template>
<script>
import Popup from "@/components/unit/Popup";
import CustomButton from "@/components/unit/CustomButton";
import itemScroll from "@/components/unit/ItemScroll";
import autoGraph from "@/components/unit/autograph";
import { defineAsyncComponent } from "vue";
import commonAgreement from "@/components/page/agreement/agreementComs/commonAgreement";
import defauleAgreement from "@/components/page/agreement/agreementComs/defauleAgreement";
import weixin from "@/common/weixin";
import PopopInstance from "@/components/unit/PopupInstance";
export default {
  name: "agreement",
  data() {
    return {
      showPopup: false,
      showSignName: false,
      agreementHTML: "",
      prewScore: 0,
      isTrue: false,
      msg: {
        // title: "评价与反馈",
        align: "center",
        btngroup: {
          // sure: "提交",
          // close: "退出",
        },
      },
      scoreDate: {
        level: 0,
        content: "",
      },
      status: true,

      signData: {
        id: "1",
        sign_time: "",
        sign_url: "",
      },
      data: {
        sign_url: "",
        meet_start_time: "2023-07-01",
      },
      config: {
        nullText: /<(div|p)\s*class=[\s\w\"]*><\/(div|p)>/g,
        agreementFields: [],
        projectList: [
          {
            projectID: [1, 2],
            projectName: "commonAgreement",
          },
        ],
        agreementList: {
          commonAgreement: [
            {
              agreement: "defauleAgreement",
              start_time: "", //协议开始使用的时间
              end_time: "2023/07/31 23:59:59", //协议截至使用的时间
            },
            {
              agreement: "commonAgreement",
              start_time: "2023/08/01 00:00:00",
              end_time: "",
            },
          ],
        },
        signNameUrlList: ["", ""],
        signNameUrlIndex: 0,
      },
      showShare: false,
      componentsName: "",
    };
  },
  components: {
    autoGraph,
    itemScroll,
    commonAgreement,
    defauleAgreement,
    Popup,
    CustomButton,
  },
  computed: {
    components() {
      if (!this.componentsName)
        return defineAsyncComponent(() =>
          import(`@/components/page/agreement/agreementComs/defauleAgreement`)
        );
      return defineAsyncComponent(() =>
        import(
          `@/components/page/agreement/agreementComs/${this.componentsName}`
        )
      );
    },
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = "协议签署";
    // if(!this.$route.query.id){
    //     weixin.wx.closeWindow();
    //     return;
    // }
    this.signData.id = Number(this.$route.query.meetId);
    this.signData.user_id = Number(this.$route.query.user_id);
    this.$store.dispatch("setItem", { userId: this.signData.user_id });
    this.loadData();
    // this.$tools.removeTouch()
    this.initDoctorData();
  },
  mounted() {
    weixin.wx.ready(() => {
      weixin.shareMessage({
        title: "劳务协议签署",
        url: this.getShareUrl(),
        description: "",
        logo: "",
      });
    });
  },
  methods: {
    handleRatingChange() {
      // return;
      if (this.scoreDate.level == this.prewScore && !this.isTrue) {
        this.scoreDate.level = 0;
        this.isTrue = true;
      } else {
        this.prewScore = this.scoreDate.level;
        this.isTrue = false;
      }
    },
    getShareUrl() {
      // 正式服记得改
      let url = this.$tools.getURL(`${this.$config.host}#/agreementShare`, {
        open_type: "signed",
        meetId: this.signData.id,
        user_id: this.signData.user_id,
      });
      // console.log(url)
      return url;
    },
    createSignature() {
      weixin.shareMessage({
        title: "劳务协议签署",
        url: this.getShareUrl(),
        description: "",
        logo: "",
      });
      this.showShare = true;
    },
    async initDoctorData() {
      this.$axios
        .get(this.$urls.user.info, {
          "user-id": this.signData.user_id || this.$store.state.common.userId,
        })
        .then((res) => {
          this.$store.dispatch("setItem", { userInfo: res.data });
          console.log(res.data);
          if (!this.$tools.isCanSignName(res.data)) {
            this.$router.push({
              path: "/doctorInfo",
              query: {
                user_id: res.data.id,
                from: this.$route.path,
                meetId: this.signData.id,
                type: 1,
              },
            });
          }
        })
        .catch(() => {});
    },

    loadData() {
      let url = this.$tools.getURL(this.$urls.agreement.signInfo, {
        id: this.signData.id,
        user_id: this.signData.user_id,
      });
      this.$axios
        .get(url, {
          "user-id": this.signData.user_id || this.$store.state.common.userId,
        })
        .then((res) => {
          this.data = res.data[0];
          // console.log("this.data  :>> ", res.data[0]);
          for (let k in this.data) {
            if (this.data[k] == null) {
              this.data[k] = "";
            }
          }
          this.config.signNameUrlList = this.data.sign_url?.split(",");
          let projectObj = this.config.projectList.find((x) =>
            x.projectID.includes(this.data.project_id)
          );

          if (projectObj) {
            let projectName = projectObj.projectName;
            this.componentsName = this.getAgreement(projectName);
          } else {
            this.componentsName = this.getAgreement("commonAgreement");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getAgreement(projectName) {
      let agreement = "";
      let meetDate = this.$tools.getDate(this.data.meet_start_time);
      let agreementObj = this.config.agreementList[projectName].find((x) => {
        return (
          (x.start_time == "" ||
            new Date(x.start_time) <= new Date(meetDate)) &&
          (x.end_time == "" || new Date(meetDate) <= new Date(x.end_time))
        );
      });
      if (agreementObj) {
        agreement = agreementObj.agreement;
      }

      return agreement;
    },
    initScroll() {
      console.log("initScroll");
      // this.$refs.itemScroll.initScroll();
    },
    scrollTo(scrollHeight) {
      // this.$refs.itemScroll.scrollTo(scrollHeight);
      this.$nextTick(() => {
        document.querySelector(".agreement_main").scrollTop = scrollHeight;
      });
    },
    closeSign() {
      this.showSignName = false;
    },
    getSignNameURL(signNameUrl) {
      this.config.signNameUrlList[this.config.signNameUrlIndex] = signNameUrl;

      // this.signData.sign_time = this.$tools.getDate();
      this.signData.sign_url = this.config.signNameUrlList
        .filter((x) => x != "")
        .join(",");
      this.closeSign();
    },
    doSignName(index) {
      if (this.data.sign_url) return;
      this.config.signNameUrlIndex = index;
      this.showSignName = true;
    },
    verifyData() {
      return this.$refs.agreementDOM.verifyData();
    },
    doCommit() {
      // this.showPopup = true;
      if (this.loading || !this.verifyData()) return;
      this.loading = true;
      this.$axios
        .post(this.$urls.agreement.meetSign, this.signData, {}, 4000)
        .then((res) => {
          this.$tips.success({ text: "提交成功" });
          this.data.sign_url = this.signData.sign_url;
          this.data.sign_time = this.$tools.getDate();
          this.initScroll();
          // this.showPopup = true;
          if (this.$route.query.type == 1) {
            this.$router.push({
              path: "/myMeetList",
              query: {},
            });
          } else {
            setTimeout(() => {
              weixin.wx.closeWindow();
            }, 1000);
          }
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
            this.config.signNameUrlList = [];
          }, 1000);
        });
    },
    doSure() {
      if (!this.scoreDate.level) {
        return this.$tips.error({ text: "请先为本次活动评分再提交哟" });
      }

      if (this.data.is_selection == 0) {
        this.scoreDate.is_selection = Number(this.status);
      }
      this.$axios
        .post(
          this.$urls.agreement.remark,
          {
            ...this.scoreDate,
            post_id: Number(this.$route.query.meetId),
            doctor_id: Number(this.$route.query.user_id),
          },
          {},
          4000
        )
        .then(() => {
          this.$tips.success({ text: "提交成功" });
          this.doClose(false);
        });
    },
    doClose(status = true) {
      this.showPopup = false;
      if (status) {
        this.$tips.success({ text: "协议签署成功" });
      }
      if (this.$route.query.type == 1) {
        this.$router.push({
          path: "/myMeetList",
          query: {},
        });
      } else {
        setTimeout(() => {
          weixin.wx.closeWindow();
        }, 1000);
      }
    },
    //查看公告
    checkSelection() {
      let msg = {
        title: "骨转百讯评选活动公告",
        align: "center",
        html: `<div class="selection_desc"><div>骨转百讯-肿瘤免疫临床实践热门话题# 评选、报道活动火热进行中，评选为优秀话题可获得【推医汇】公众号的特别报道，提升学术影响力。\n您参与的会议名为【${this.data.title}】的会议可参与评选活动，快来参加吧!
\n注: 待本场全部专家均同意参与后，公众号会提醒您转发点赞。</div></div>`,
        btngroup: {
          sure: "已知晓，同意参加",
          close: "不同意参加",
        },
      };
      PopopInstance(msg, false)
        .then(() => {
          this.$nextTick(() => {
            this.status = true;
          });
        })
        .catch(() => {
          // console.log("关闭");
          this.status = false;
        });
    },
  },
  beforeUnmount() {},
};
</script>
<style>
.agreement_container {
  width: 100%;
  height: 100%;
  padding: 1rem;
  background: #fff;
  overflow: hidden;
  transform: rotate(0);
  /* position: relative; */
}
.agreement_main {
  overflow: auto;
}
.agreement_content {
  width: 100%;
  background: #f6f6f6;
}
span.agreement_underline {
  text-decoration: underline;
  /* height: 100%; */
  /* text-underline-offset: 2vw */
}
.agreement_main {
  height: 100%;
}
.agreement_main div.agreement_underline {
  border-bottom: 1px solid #000;
  /* padding: 0 1rem; */
  /* height: 100%; */
}
.agreement_main h1 {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}
.agreement_main h3 {
  width: 100%;
  font-size: 1rem;
  margin: 0.75rem 0;
}
.agreement_main b {
  font-weight: 500;
}
.agreement_main p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.agreement_main p,
.agreement_main div {
  line-height: 1.7rem;
  /* width: 100%; */
}
.agreemtn_button_submit {
  width: 100%;
  margin-top: 2rem;
}
.agreemtn_button_submit .el-button {
  border-radius: 1.25rem;
  min-width: 50%;
}
.agreement_button_item {
  width: 74.66%;
  height: 2.35rem;
}
.agreement_sign_info {
  display: flex;
}
.agreement_sign_info > div {
  flex: 1 0 0;
  /* margin-top:6vw; */
}
.agreement_sign_name {
  height: 4rem;
  display: flex;
  align-items: center;
}
.agreement_sign_name .customButton_container {
  line-height: 1.4rem !important;
  height: 1.4rem !important;
  width: 4rem;
}
.agreement_sign_name span {
  white-space: nowrap;
}
.agreement_sign_name img {
  width: 6rem;
  height: auto;
}
.showShare_block {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.showShare_bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #232327;
  opacity: 0.5;
}
.showShare_main {
  position: relative;
  z-index: 100;
  height: 100%;
}
.showShare_img {
  height: auto;
  width: 100%;
}

.agreement-popup-main {
  /* padding: 1rem 0; */
  font-size: 0.8rem;
  line-height: 1.2rem;
}
.agreement-popup-content {
  margin-top: 1rem;
}
.agreement-popup-tag {
  /* color: #e64638; */
  margin: 0.5rem 0;
}
.agreement-popup-main .el-rate {
  --el-rate-icon-size: 2rem;
  height: 2rem;
  margin-top: 0.5rem;
}
.agreement_popup_btn_group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.agreement_popup_btn_sure {
  width: 80%;
  margin: 0.5rem 0;
  /* letter-spacing: 0.2rem; */
}
.agreement_popup_btn_close {
  /* border-bottom: 1px solid #423e3e; */
  text-decoration: underline;
  color: #423e3e;
  /* letter-spacing: 0.2rem; */
}
.selection_desc {
  text-align: center;
  font-size: 0.9rem;
  margin: 1rem 0;
}
.agreement-content-indent {
  text-indent: 1rem;
}
</style>
